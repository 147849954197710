import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/structure-your-sass",
  "date": "2014-03-28",
  "title": "STRUCTURE YOUR SASS",
  "author": "admin",
  "tags": ["development", "css", "sass"],
  "featuredImage": "feature.jpg",
  "excerpt": "Sass enables your you to separate your stylesheets into individual files, and then you can use the @import directive to include your individual files into one master stylesheet."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Let's look into how you can structure your individual files.`}</p>
    <h2>{`Basic directory structure`}</h2>
    <p>{`In your `}<strong parentName="p">{`sass`}</strong>{` folder, you can layout the files as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`tree
sass/
|
|-- modules/              # Common modules
|   |-- _all.scss         # Include to get all modules
|   |-- _utility.scss     # Module name
|   |-- _colors.scss      # Etc...
|   ...
|
|-- partials/             # Partials
|   |-- _base.sass        # imports for all mixins + global project variables
|   |-- _buttons.scss     # buttons
|   |-- _figures.scss     # figures
|   |-- _grids.scss       # grids
|   |-- _typography.scss  # typography
|   |-- _reset.scss       # reset
|   ...
|
|-- vendor/               # CSS or Sass from other projects
|   |-- _colorpicker.scss
|   |-- _jquery.ui.core.scss
|   ...
|
|-- main.scss            # primary Sass file
`}</code></pre>
    <h2>{`Primary stylesheet`}</h2>
    <p>{`This allows you to keep your primary Sass file (main.scss in this example) extremely clean:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`// Modules and Variables
@import "partials/base";

// Partials
@import "partials/reset";
@import "partials/typography";
@import "partials/buttons";
@import "partials/figures";
@import "partials/grids";
// ...

// Third-party
@import "vendor/colorpicker";
@import "vendor/jquery.ui.core";
`}</code></pre>
    <h2>{`Modules, partials, and vendor`}</h2>
    <p>{`As you can see this divides your project into three basic types of files. Modules, partials, and vendored stylesheets.`}</p>
    <ul>
      <li parentName="ul">{`The `}<strong parentName="li">{`modules`}</strong>{` directory is reserved for Sass code that doesn't cause Sass to actually output CSS. Things like mixin declarations, functions, and variables.`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`partials`}</strong>{` directory is where the meat of your CSS is constructed. A lot of people would like to break their stylesheets into header, content, sidebar, and footer components (and a few others). But ideally you should break things down into much finer categories (typography, buttons, textboxes, selectboxes, etc…).`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`vendor`}</strong>{` directory is for third-party CSS. This is handy when using prepackaged components developed by other people (or for your own components that are maintained in another project). jQuery UI and a color picker are examples of CSS that you might want to place in the vendor directory. As a general rule it's better to make it a point not to modify files in your vendor directory. If you need to make modifications, add those after the vendored files. This should make it easy for you to update your third-party stylesheets to more current versions in the future.`}</li>
    </ul>
    <h2>{`Using a base partial`}</h2>
    <p>{`In the partials directory you will also notice that there is a base partial. The purpose of this partial is to load up Sass environment so that it's easy to construct a stylesheet.
It might look something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`// Use Compass ('cause it rocks!)
@import "compass";

// Font weights
$light: 100;
$regular: 400;
$bold: 600;

// Base Font
$base-font-family: sans-serif;
$base-font-weight: $regular;
$base-font-size: 13px;
$base-line-height: 1.4;

// Fixed Font
$fixed-font-family: monospace;
$fixed-font-size: 85%;
$fixed-line-height: $base-line-height;

// Headings
$header-font-weight: $bold;

@import "modules/all";
`}</code></pre>
    <p>{`The base stylesheet sets a couple of global variables and loads up all of Sass modules. Again modules are not allowed to contain anything that would cause CSS output when importing. Tying all of your varibles and modules up into a base partial gives the access to your entire Sass environment whenever you are setting up a new stylesheet with a single import statement. This allows you to build multiple stylesheets by importing different partials. Multiple stylesheets are handy once a project grows to a certain size.`}</p>
    <h2>{`One step further`}</h2>
    <p>{`You can take this pattern one step further. Since we have multiple sub-projects all bundled together in a single app, we bundle each sub-project into a separate top-level directory. Our stylesheet directory looks more like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`tree
sass/
|
|-- admin/           # Admin sub-project
|   |-- modules/
|   |-- partials/
|   |-- _base.scss
|
|-- account/         # Account sub-project
|   |-- modules/
|   |-- partials/
|   |-- _base.scss
|
|-- site/            # Site sub-project
|   |-- modules/
|   |-- partials/
|   |-- _base.scss
|
|-- vendor/          # CSS or Sass from other projects
|   |-- _colorpicker-1.1.scss
|   |-- _jquery.ui.core-1.9.1.scss
|   ...
|
|-- admin.scss       # Primary stylesheets for each project
|-- account.scss
|-- site.scss
`}</code></pre>
    <p>{`As you can see each sub-project has it's own primary stylesheet, modules, partials, and base. Vendored stylesheets are typically versioned and have their own top-level directory. This is a handy pattern to use on very large Sass projects.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      